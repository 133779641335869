// Footer.js
import React from 'react';
import "./Footer.css";
function Footer() {
  return (
    <footer>
      <p>&copy; 2023 superdan1505.com</p>
    </footer>
  );
}

export default Footer;
