// Home.js
import React from 'react';
import "./Home.css";

function Home() {
  return (
    <div className="home">
      <h1>Josh Lennon</h1>
      <img src={process.env.PUBLIC_URL + '66ECDF03-5985-4167-8E9F-31935E3EFD29.jpg'} alt="Headshot" />
      <h2>Josh Lennon</h2>
      <p>
        Born near London, England
        <br /> {/* Use <br /> for a line break */}
        Living & studying in Manchester, England
      </p>
      <h2>Education</h2>
      <p>
        2019 - 2021: Fullbrook 6th Form
        <br /> {/* Use <br /> for a line break */}
        • Computing A*
        <br />
        • Mathematics A*
        <br />
        • Physics A
        <br />
        2021 – PRESENT: University of Manchester <i>Expected graduation: 2024</i>
      </p>
    </div>
  );
}

export default Home;
