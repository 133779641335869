// Blog.js
import React from 'react';
import Post from '../Post';
import YouTube from 'react-youtube';
import './Blog.css';

const Blog = () => {
  const posts = [
    {
      username: 'Testing for Neural Network Project',
      message: 'Video series demonstrating functionality of neural network project.',
      timestamp: '2023-10-08 20:54 PM',
      videoId: 'BkU7MrMFVHk',
    },
    {
      username: 'Neural Network Visual Basic Project',
      message: 'Independently researched neural network machine learning (using the Iris data set) + documented the theory across hundreds of pages. Coded in VB using NO libraries. This required intensive object-oriented programming with a deep understanding of the fundamental maths behind neural networks.',
      timestamp: '2023-10-08 11:30 AM',
      pdfUrl: 'https://example.com/path-to-your-pdf.pdf',
      // Replace with your YouTube video ID
    },
    
    // Add more posts as needed
  ];

  return (
    <div className="blog-container" style={{ backgroundColor: '#f0f0f0' }}>
      <h2>Latest Posts</h2>
      {posts.map((post, index) => (
        <div key={index} className="post">
          <Post
            username={post.username}
            message={post.message}
            timestamp={post.timestamp}
          />
          {post.videoId && (
            <YouTube
              videoId={post.videoId}
              opts={{ width: '100%', height: '400px' }}
            />
          )}
          {post.pdfUrl && (
            <div className="pdf-embed">
              <embed src={post.pdfUrl} type="application/pdf" width="100%" height="500px" />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Blog;
