// Post.js
import React from 'react';
import PropTypes from 'prop-types';
import "./Post.css";

function Post({ username, message, timestamp }) {
  return (
    <div className="post">
      <div className="post-header">
        <span className="post-username">{username}</span>
        <span className="post-timestamp">{timestamp}</span>
      </div>
      <p className="post-message">{message}</p>
    </div>
  );
}

Post.propTypes = {
  username: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default Post;
